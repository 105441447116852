import React, { useState } from "react"
import uniqBy from "lodash/uniqBy"
import without from "lodash/without"
import compact from "lodash/compact"
import flatten from "lodash/flatten"
import { Button } from "reactstrap"

import Actions from "./actions"
import { addChoiceSetsToAnnotatedAction, Annotated } from "../../hasura/slices/passage"
import { conceptSelector, ConceptState } from "../../hasura/slices/concept"
import { curriculumSelector, CurriculumState } from "../../hasura/slices/curriculum"
import { includeRightMargin } from "../../lib/helpers"
import { insertChoiceSetAction, updateChoiceSetAction } from "../../hasura/slices/choiceSet"
import { useDispatch, useSelector } from "react-redux"
import { userSelector, UserState } from "../../hasura/slices/user"

// @ts-ignore
import deleteIcon from "../../lib/images/delete.svg"

interface Props {
  annotated: Annotated
  handleClickedContinue: () => void
}

export default function ChoiceSets(props: Props) {
  const dispatch = useDispatch()

  const [isAddingChoiceSet, setIsAddingChoiceSet] = useState(false)
  const [choiceSetValues, setChoiceSetValues] = useState<string[] | undefined>()
  const [choiceSetValue, setChoiceSetValue] = useState<string[] | undefined>()
  const [choiceSetId, setChoiceSetId] = useState<number | undefined>()

  const { accessToken }: UserState = useSelector(userSelector)
  const { concepts }: ConceptState = useSelector(conceptSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)

  const handleClickedContinue = async () => {
    if (choiceSetId) {
      await dispatch(updateChoiceSetAction(accessToken!, choiceSetId, choiceSetValues!))
    } else {
      const choiceSet = { concepts_denormalized: choiceSetValues!.join(), curriculum_id: curriculum?.id }
      await dispatch(insertChoiceSetAction(accessToken!, choiceSet))
    }

    await dispatch(addChoiceSetsToAnnotatedAction(props.annotated, concepts, curriculum!.id))

    reset()
  }

  const reset = () => {
    setChoiceSetId(undefined)
    setChoiceSetValue(undefined)
    setChoiceSetValues(undefined)
  }

  const tokens = props.annotated.tokens

  const passage = (
    <p className="d-flex flex-wrap text-l m-0">
      {tokens.map((token, idx) => {
        return (
          <span
            key={token.index}
            className={`
              ${includeRightMargin(tokens, token, idx) ? "mr-1" : ""}
              ${token.redHerringSets?.length && !isAddingChoiceSet ? "text--primary bold" : ""}
              ${isAddingChoiceSet ? "pointer text--gray6" : "pe-none"}
            `}
            onClick={() => {
              setIsAddingChoiceSet(false)
              setChoiceSetValues([token.lemma])
            }}
          >
            {token.value}
          </span>
        )
      })}
    </p>
  )

  return (
    <div className="tagging-step">
      <div />

      <div className="tagging-step-content">
        {choiceSetValues ? (
          <div className="tagging-step-inner-content">
            {passage}

            <div className="my-2">
              {choiceSetValues.sort().map((value) => (
                <div key={value} className="d-flex align-items-center">
                  <img className="icon-xs svg-icon hover mr-2" onClick={() => setChoiceSetValues(without(choiceSetValues, value))} src={deleteIcon} />

                  <p className="text-s m-0" key={value}>
                    {value}
                  </p>
                </div>
              ))}
            </div>

            <div className="d-flex">
              <input
                className="max-width-300px flex-grow"
                autoFocus
                onChange={(e: any) => setChoiceSetValue(e.target.value)}
                value={choiceSetValue || ""}
              />

              <Button
                onClick={() => {
                  setChoiceSetValues(choiceSetValues.concat(choiceSetValue!))
                  setChoiceSetValue(undefined)
                }}
                disabled={!choiceSetValue}
                color="success"
                className="ml-3 min-width-100px"
              >
                Add
              </Button>
            </div>

            <Button className="max-width-150px mt-3" onClick={handleClickedContinue} color="primary">
              Continue
            </Button>
          </div>
        ) : (
          <div className="tagging-step-inner-content">
            {passage}

            <div className="my-3">
              {uniqBy(compact(flatten(tokens.map((t) => t.redHerringSets))), "choiceSetId").map((redHerringSet, idx) => {
                return (
                  <div className={`my-2 d-flex align-items-start w-100 ${isAddingChoiceSet ? "hidden" : ""}`} key={idx}>
                    <Button
                      onClick={() => {
                        setChoiceSetValues([redHerringSet.value].concat(...redHerringSet.values))
                        setChoiceSetId(redHerringSet.choiceSetId)
                      }}
                      disabled={!redHerringSet.choiceSetId}
                      color="secondary"
                      size="sm"
                      className="mr-3"
                    >
                      Edit
                    </Button>

                    <p className="m-0 text-m">
                      {redHerringSet.value} / {redHerringSet.values.join(" / ")}
                    </p>
                  </div>
                )
              })}
            </div>

            <div>
              <Button
                className="min-width-150px"
                onClick={() => setIsAddingChoiceSet(!isAddingChoiceSet)}
                color={isAddingChoiceSet ? "danger" : "success"}
                outline={isAddingChoiceSet}
              >
                {isAddingChoiceSet ? "Cancel" : "Create new"}
              </Button>
            </div>
          </div>
        )}
      </div>

      <Actions disabled={Boolean(choiceSetValues?.length)} handleClickedContinue={props.handleClickedContinue} />
    </div>
  )
}
