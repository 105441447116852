import React, { useEffect, useState } from "react"
import Slider from "rc-slider"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import { Annotated, fetchPassagesForIdsAction, UnenrichedPassage, updatePassageMetadataAction } from "../../hasura/slices/passage"
import { deleteIllustratedPassageAction, setGalleryEntityAction } from "../../hasura/slices/image"
import { imageFor, pluralize } from "../../lib/helpers"
import { userSelector, UserState } from "../../hasura/slices/user"

interface Props {
  passage?: UnenrichedPassage
  annotated?: Annotated
  setMetadataIsValid: (metadataIsValid: boolean) => void
  passageText?: string
}

export default function Metadata(props: Props) {
  const dispatch = useDispatch()

  const { accessToken }: UserState = useSelector(userSelector)

  const [difficulty, setDifficulty] = useState(props.passage?.difficulty)
  const [quality, setQuality] = useState(props.passage?.quality)

  const passage = props.passage

  useEffect(() => {
    props.setMetadataIsValid(quality !== null && difficulty !== null)
    if (!accessToken) return

    dispatch(updatePassageMetadataAction(accessToken, passage!.id, quality || null, difficulty || null))
  }, [accessToken, difficulty, quality])

  const handleDeleteIllustratedImage = async (id: number) => {
    if (!window.confirm("Are you sure you want to remove this image?")) return

    await dispatch(deleteIllustratedPassageAction(accessToken!, id))
    dispatch(fetchPassagesForIdsAction(accessToken!))
  }

  return (
    <div className="tagging-step-content">
      <div className="tagging-step-inner-content">
        <p className="text-l mb-0">{props.passageText}</p>

        <div className="my-4">
          <div className="w-100 d-flex overflow-scroll">
            {passage?.illustrated_passages.map((i) => (
              <img
                onClick={() => handleDeleteIllustratedImage(i.id)}
                style={{ height: "200px", width: "auto" }}
                className="mr-3"
                src={imageFor(i.image.s3_path)}
                key={i.image.s3_path}
              />
            ))}
          </div>

          {(passage?.illustrated_passages.length || 0) > 0 && (
            <p className="text-xs m-0 mt-1 text--gray7">{pluralize("image", passage!.illustrated_passages.length)}</p>
          )}
        </div>

        <div>
          <Button color="success" onClick={() => dispatch(setGalleryEntityAction(passage))}>
            Search images
          </Button>
        </div>

        <div className="d-flex align-items-center mt-4">
          <p style={{ width: "80px" }} className="text-xxs text-uppercase bold text--gray8 mb-0">
            difficulty
          </p>

          <Slider
            // @ts-ignore
            onChange={(difficulty) => setDifficulty(difficulty)}
            defaultValue={difficulty || undefined}
            min={1}
            max={10}
            marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }}
          />
        </div>

        <div className="d-flex align-items-center mt-4">
          <p style={{ width: "80px" }} className="text-xxs text-uppercase bold text--gray8 mb-0">
            quality
          </p>

          <Slider
            // @ts-ignore
            onChange={(quality) => setQuality(quality)}
            defaultValue={quality || undefined}
            min={1}
            max={10}
            marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }}
          />
        </div>
      </div>
    </div>
  )
}
