import React, { useEffect, useState } from "react"
import cloneDeep from "lodash/cloneDeep"
import compact from "lodash/compact"
import extend from "lodash/extend"
import flatten from "lodash/flatten"
import isEqual from "lodash/isEqual"
import last from "lodash/last"
import omit from "lodash/omit"
import range from "lodash/range"
import without from "lodash/without"
import { Button, Spinner } from "reactstrap"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import Actions from "../components/tagging/actions"
import ChoiceSets from "../components/tagging/choiceSets"
import ConfirmModal from "../components/tagging/confirm"
import CustomQuestions from "../components/tagging/customQuestions"
import Layout from "../components/layout"
import Metadata from "../components/tagging/metadata"
import SEO from "../components/seo"
import TaggingNavigation from "../components/tagging/navigation"
import TokenRedHerrings from "../components/tagging/tokenRedHerrings"
import callFlask from "../callFlask"
import config from "../config"
import { ADMIN_ACCEPT_PASSAGE, ADMIN_ARCHIVE_PASSAGE } from "../lib/pedagogeEventTypeIds"
import { Annotated, fetchPassagesForIdsAction, passageSelector, PassageState } from "../hasura/slices/passage"
import { ChatGPT, CustomQuestion, queryChatGpt } from "../services/chatGpt"
import { CurriculumState, curriculumSelector } from "../hasura/slices/curriculum"
import { NotificationId, setNotificationAction } from "../hasura/slices/notification"
import { QueryName } from "../hasura/queryNames"
import { Step, STEP_ORDER } from "../components/tagging/config"
import { conceptSelector, ConceptState, fetchConceptsAction } from "../hasura/slices/concept"
import { createPermutations, joinTokens } from "../components/tagging/helpers"
import { fetchUserAction, reviewOutsourcerPassageAction, userSelector, UserState } from "../hasura/slices/user"
import { searchQueryParams, includeRightMargin, tokensJoined } from "../lib/helpers"
import { setGalleryEntity } from "../hasura/slices/image"
import { usePrevious } from "../hooks/usePrevious"

import {
  Token,
  Triple,
  annotateTextAction,
  setAnnotatedAction,
  updatePassageAction,
  UnenrichedPassage,
  unsetUnenrichedPassagesForAdminAction,
} from "../hasura/slices/passage"

// @ts-ignore
import plusIcon from "../lib/images/plus.svg"
// @ts-ignore
import minusIcon from "../lib/images/minus.svg"

const INITIAL_STEP = config.IS_DEVELOPMENT ? Step.Metadata : Step.EditText

export default function Tagging() {
  const dispatch = useDispatch()

  const { accessToken, user }: UserState = useSelector(userSelector)
  const { unenrichedPassages, annotated, isQuerying }: PassageState = useSelector(passageSelector)
  const { concepts }: ConceptState = useSelector(conceptSelector)
  const { curriculum }: CurriculumState = useSelector(curriculumSelector)

  const [customTriples, setCustomTriples] = useState<Triple[]>([])
  const [displayConfirm, setDisplayConfirm] = useState(false)
  const [gamifiedSentenceIndex, setGamifiedSentenceIndex] = useState<number | undefined>()
  const [gamifiedSentenceOptions, setGamifiedSentenceOptions] = useState<any[] | undefined>()
  const [index, setIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isNetworking, setIsNetworking] = React.useState(false)
  const [metadataIsValid, setMetadataIsValid] = useState(false)
  const [object, setObject] = useState<Token[] | undefined>()
  const [passage, setPassage] = useState<UnenrichedPassage | undefined>()
  const [permutationIndexes, setPermutationIndexes] = useState<number[]>([])
  const [questions, setQuestions] = React.useState<CustomQuestion[]>([])
  const [redHerringSetIdx, setRedHerringSetIdx] = useState(0)
  const [relation, setRelation] = useState<Token[] | undefined>()
  const [sentenceIndexes, setSentenceIndexes] = useState<number[] | undefined>()
  const [step, setStep] = useState<Step>(STEP_ORDER[0])
  const [subject, setSubject] = useState<Token[] | undefined>()
  const [text, setText] = useState<string | undefined>()
  const [tokenIdx, setTokenIdx] = useState(0)
  const [tokenIndexes, setTokenIndexes] = useState<number[]>([])

  const inQueue = searchQueryParams("ids") !== undefined
  const isReviewing = searchQueryParams("r")

  const previousUnenrichedPassages = usePrevious(unenrichedPassages)
  const previousIndex = usePrevious(index)
  const previousAnnotated = usePrevious(annotated)

  const possiblePermutations = createPermutations(subject, object, relation)

  const invalid = annotated?.tokens.map((token, idx) => ({ token, idx })).filter((t) => t.token.redHerringSets) || []

  const customTriple = {
    subjectIndexes: tokenIndexes.filter((t) => subject?.some((s) => s.index === t)),
    relationIndexes: tokenIndexes.filter((t) => relation?.some((s) => s.index === t)),
    objectIndexes: tokenIndexes.filter((t) => object?.some((s) => s.index === t)),
  }

  const [pretext, posttext] =
    gamifiedSentenceIndex !== undefined
      ? [
          gamifiedSentenceOptions!.slice(0, gamifiedSentenceIndex).map(tokensJoined).join(" ").trim() || null,
          gamifiedSentenceOptions!
            .slice(gamifiedSentenceIndex! + 1)
            .map(tokensJoined)
            .join(" ")
            .trim() || null,
        ]
      : [null, null]

  const passageText =
    STEP_ORDER.indexOf(step) > STEP_ORDER.indexOf(Step.GamifiedSentence)
      ? compact([annotated?.pretext || pretext, annotated?.text, annotated?.posttext || posttext]).join(" ")
      : undefined

  /*
    Effects
  */

  useEffect(() => {
    if (!passage || step !== Step.SelectSentences) return

    if (sentenceIndexes?.length) {
      setText(passage!.original_text_sentence_tokenized.slice(sentenceIndexes[0], last(sentenceIndexes)! + 1).join(" "))
    } else {
      setSentenceIndexes([passage?.original_text_target_sentence_index || 0])
    }
  }, [step, passage, sentenceIndexes])

  useEffect(() => {
    if (!unenrichedPassages?.length) return

    const passage = unenrichedPassages[index]

    if (passage) {
      setPassage(passage)

      if (unenrichedPassages?.length === previousUnenrichedPassages?.length && index === previousIndex) {
        return
      }

      setSubject(undefined)
      setRelation(undefined)
      setObject(undefined)
      setCustomTriples([])
      setPermutationIndexes([])
      setRedHerringSetIdx(0)
      setSentenceIndexes(undefined)
      setTokenIdx(0)
      setTokenIndexes([])
      setGamifiedSentenceIndex(undefined)
      setGamifiedSentenceOptions([])
      setMetadataIsValid(false)

      if (passage.annotated) {
        setText(compact([passage.annotated.pretext, passage.annotated.text, passage.annotated.posttext]).join(" "))
        dispatch(setAnnotatedAction(isReviewing ? undefined : passage.annotated))
        setStep(INITIAL_STEP)
      } else if (passage.text) {
        setText(passage.text)
        setStep(Step.EditText)
      } else {
        dispatch(setAnnotatedAction(undefined))
        const isSingleSentence = passage.original_text_sentence_tokenized.length === 1
        if (isSingleSentence) setText(passage.original_text_sentence_tokenized[0])
        setSentenceIndexes([passage.original_text_target_sentence_index || 0])
        setStep(STEP_ORDER[isSingleSentence ? 1 : 0])
      }
    } else {
      navigate("/passages")
    }
  }, [unenrichedPassages, previousUnenrichedPassages, index, previousIndex])

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchPassagesForIdsAction(accessToken!))
    dispatch(fetchConceptsAction(accessToken))
    return () => {
      dispatch(unsetUnenrichedPassagesForAdminAction())
    }
  }, [accessToken])

  useEffect(() => {
    ;(async () => {
      if (step === Step.ValidateRelation && !relation?.length) {
        setStep(Step.CorrectRelation)
      }

      if (step === Step.ValidateObject && !object?.length) {
        setStep(Step.CorrectObject)
      }

      if (step === Step.TriplePermutations && possiblePermutations) {
        setPermutationIndexes(range(possiblePermutations.length))
      }

      if (step === Step.RedHerrings) {
        setTokenIdx(0)
      }

      if (step === Step.Metadata) {
        dispatch(setGalleryEntity(extend({}, passage, { annotated })!))
      }

      if (step === Step.Finish) {
        let add = {
          subjectIndexes: subject?.map((o) => o.index),
          relationIndexes: relation?.map((o) => o.index),
          objectIndexes: object?.map((o) => o.index),
          triples: customTriples.length
            ? customTriples
            : (possiblePermutations || [])
                .filter((_, idx) => permutationIndexes.includes(idx))
                .map((p) => ({
                  objectIndexes: p.object.map((o) => o.index),
                  subjectIndexes: p.subject.map((o) => o.index),
                  relationIndexes: p.relation!.map((o) => o.index),
                })),
        }
        if (gamifiedSentenceIndex !== undefined) add = extend(add, { pretext, posttext })
        const remove = ["subjectHeadIndexes", "relationHeadIndexes", "objectHeadIndexes"]
        // @ts-ignore
        const cloned: Annotated = omit(extend(cloneDeep(annotated)!, add), remove)
        if (isReviewing) {
          dispatch(reviewOutsourcerPassageAction(accessToken!, user!, passage?.id!, searchQueryParams("r")!, ADMIN_ACCEPT_PASSAGE))
        }
        await dispatch(updatePassageAction(cloned, user!.id, passage!.id))
        await dispatch(fetchUserAction(accessToken!, user!.id))
        dispatch(setNotificationAction(NotificationId.PassageSaved))
        setIndex(index + 1)
      }
    })()
  }, [step, sentenceIndexes])

  useEffect(() => {
    if (!annotated || isEqual(annotated, previousAnnotated) || ![Step.EditText, Step.GamifiedSentence].includes(step)) {
      return
    }

    const subject = annotated?.tokens.filter((_, idx) => (annotated.subjectIndexes || []).includes(idx))
    setSubject(subject)
    setRelation(annotated?.tokens.filter((_, idx) => (annotated.relationIndexes || []).includes(idx)))
    setObject(annotated?.tokens.filter((_, idx) => (annotated.objectIndexes || []).includes(idx)))
    setStep(subject?.length ? Step.ValidateSubject : Step.CorrectSubject)
  }, [annotated, previousAnnotated, step])

  useEffect(() => {
    if (passage?.custom_questions) setQuestions(cloneDeep(passage.custom_questions))
  }, [passage])

  useEffect(() => {
    if (passage?.custom_questions || !passageText) return

    handleQueryChatGpt(ChatGPT.Model3p5)
  }, [passageText, passage])

  const handleQueryChatGpt = async (model: ChatGPT) => {
    setIsNetworking(true)
    const content = `Acting as a teacher, write six multiple choice questions in JSON array format based on the following passage. Each question should have the keys question as a string, choices as an array of strings, and answer as a string. Here is the passage:
        ${passageText}
      `
    const questions = await queryChatGpt(content, model)
    setQuestions(questions)
    setIsNetworking(false)
  }

  /*
    Methods
  */

  const addTriplePermutation = () => {
    setCustomTriples(customTriples.concat(customTriple))
    setTokenIndexes([])
  }

  const annotateText = async () =>
    dispatch(
      annotateTextAction(
        gamifiedSentenceIndex === undefined ? text! : tokensJoined(gamifiedSentenceOptions![gamifiedSentenceIndex]),
        concepts,
        curriculum!.id
      )
    )

  const continueInvalid = () => {
    const token = annotated?.tokens[invalid[tokenIdx].idx]
    const hasRedHerrings = (token?.acceptedRedHerrings?.length || 0) > 0

    if (!hasRedHerrings && token?.redHerringSets![redHerringSetIdx + 1]) {
      setRedHerringSetIdx(redHerringSetIdx + 1)
    } else if (invalid[tokenIdx + 1]) {
      setRedHerringSetIdx(0)
      setTokenIdx(tokenIdx + 1)
    } else {
      setStep(Step.CustomQuestions)
    }
  }

  const handleCorrectedSubject = () => {
    if (!tokenIndexes.length) return

    setSubject(annotated?.tokens.filter((_, idx) => tokenIndexes.includes(idx)))
    setTokenIndexes([])
    goToRelationStep()
  }

  const handleCorrectedRelation = () => {
    if (!tokenIndexes.length) return

    setRelation(annotated?.tokens.filter((_, idx) => tokenIndexes.includes(idx)))
    setTokenIndexes([])
    goToObjectStep()
  }

  const handleCorrectedObject = () => {
    if (!tokenIndexes.length) return

    setObject(annotated?.tokens.filter((_, idx) => tokenIndexes.includes(idx)))
    setTokenIndexes([])
    setStep(Step.TriplePermutations)
  }

  const handleNoTriple = () => {
    setSubject([])
    setRelation([])
    setObject([])
    setStep(Step.ChoiceSets)
  }

  const handleUpdateRedHerrings = (accept: boolean, concept: string) => {
    const cloned = cloneDeep(annotated)!
    const token = cloned?.tokens[invalid[tokenIdx].idx]
    if (concept) {
      token!.acceptedRedHerrings = accept ? token!.acceptedRedHerrings!.concat(concept) : without(token!.acceptedRedHerrings, concept!)
    } else if (accept) {
      token!.acceptedRedHerrings = [] // token!.redHerringSets
    } else {
      token!.acceptedRedHerrings = []
    }
    dispatch(setAnnotatedAction(cloned))
  }

  const handleSelectAllRedHerrings = (all: boolean) => {
    const cloned = cloneDeep(annotated)!
    const token = cloned?.tokens[invalid[tokenIdx].idx]
    token!.acceptedRedHerrings = all ? token!.redHerringSets![redHerringSetIdx]!.values : []
    dispatch(setAnnotatedAction(cloned))
  }

  const goToRedHerringsStep = () => setStep(invalid.length ? Step.RedHerrings : Step.CustomQuestions)
  const goToChoiceSetsStep = () => setStep(Step.ChoiceSets)
  const goToRelationStep = () => setStep(relation?.length ? Step.ValidateRelation : Step.CorrectRelation)
  const goToObjectStep = () => setStep(object?.length ? Step.ValidateObject : Step.CorrectObject)

  const handleSkip = () => setIndex(index + 1)

  const allRedHerringsAccepted = (token: Token, setIdx: number) =>
    token.acceptedRedHerrings?.length === (token.redHerringSets || [])[setIdx].values.length

  const sentenceTokenizeText = async () => {
    setIsLoading(true)
    const sentences = await callFlask("/sentence-tokenize", "POST", { text: text?.replace(/"/g, " ") })
    if (sentences.length > 1) {
      setStep(Step.GamifiedSentence)
      setGamifiedSentenceOptions(sentences)
    } else {
      annotateText()
    }
    setIsLoading(false)
  }

  const reviewItem = async (pedagogueEventId: number) => {
    await dispatch(reviewOutsourcerPassageAction(accessToken!, user!, passage!.id, searchQueryParams("r")!, pedagogueEventId))
    dispatch(setNotificationAction(pedagogueEventId === ADMIN_ARCHIVE_PASSAGE ? NotificationId.PassageArchived : NotificationId.PassageRejected))
    setIndex(index + 1)
  }

  /*
    Components
  */

  const tripleComponents = (tokens?: Token[], highlight?: Token[]) =>
    (tokens || []).map((token, idx) => (
      <p
        key={idx}
        className={`
          ${includeRightMargin(tokens || [], token, idx) ? "mr-1" : ""}
          text-l m-0 position-relative
          ${!highlight || highlight.some((h) => h.index === token.index) ? "text--primary bold" : ""}
      `}
      >
        {token.value}
      </p>
    ))

  const passageComponent = (interactive: boolean, step?: Step) => {
    const addingTriplePermutations = step === Step.TriplePermutations
    const tokens =
      annotated?.tokens.filter((t) => !addingTriplePermutations || flatten([subject, relation, object]).some((t2) => isEqual(t, t2))) || []

    const allSelected = tokenIndexes.length === tokens.length

    return (
      <div className="d-flex align-items-end">
        <p className="text-l m-0">
          {tokens.map((token, idx) => {
            const selected = tokenIndexes.includes(token.index)

            return (
              <span
                key={token.index}
                onClick={() => {
                  if (!interactive) return

                  setTokenIndexes(selected ? without(tokenIndexes, token.index) : tokenIndexes.concat(token.index))
                }}
                role={interactive ? "button" : ""}
                className={`
                  d-inline-block 
                  ${includeRightMargin(tokens, token, idx) ? "mr-1" : ""}
                  ${!interactive || selected ? "bold text--primary" : "text--gray4"}
                `}
              >
                {token.value}
              </span>
            )
          })}
        </p>

        {interactive && (
          <Button onClick={() => setTokenIndexes(allSelected ? [] : tokens.map((t) => t.index))} color="primary" className="nowrap ml-3" outline>
            {allSelected ? "Unselect" : "Select"} all
          </Button>
        )}
      </div>
    )
  }

  const targetIdx = passage?.original_text_target_sentence_index || 0
  const hasIndexes = (sentenceIndexes?.length || 0) > 0
  const canRemovePreviousSentence = hasIndexes && sentenceIndexes![0] < targetIdx
  const canAddPreviousSentence = hasIndexes && sentenceIndexes![0] !== 0
  const canRemoveNextSentence = hasIndexes && last(sentenceIndexes)! > targetIdx
  const canAddNextSentence = hasIndexes && last(sentenceIndexes)! !== passage?.original_text_sentence_tokenized.length - 1

  return (
    <Layout noHeader noStyles>
      <SEO title="Tagging" />

      {displayConfirm && passage && <ConfirmModal annotated={annotated} text={text} passage={passage} close={() => setDisplayConfirm(false)} />}

      <div className="vw-100 flex-center">
        <div className="tagging-box d-flex flex-column justify-content-between w-100 m-0-auto">
          <TaggingNavigation
            concepts={passage?.concepts_denormalized?.split(",") || []}
            displayConfirmModal={() => setDisplayConfirm(true)}
            id={passage?.id}
            inQueue={inQueue}
            isReviewing={isReviewing}
            passage={passage}
            passageText={passageText}
            reviewItem={reviewItem}
            setStep={setStep}
            skip={handleSkip}
            step={step}
          />

          {step === Step.SelectSentences && !isQuerying[QueryName.FetchPassages] && sentenceIndexes && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div>
                  <img
                    onClick={() => setSentenceIndexes(sentenceIndexes.slice(1, sentenceIndexes.length))}
                    src={minusIcon}
                    className={`${canRemovePreviousSentence ? "pointer" : "opacity-25 pe-none"} icon-m mr-4`}
                  />

                  <img
                    onClick={() => setSentenceIndexes([sentenceIndexes![0] - 1].concat(...sentenceIndexes!))}
                    src={plusIcon}
                    className={`${canAddPreviousSentence ? "pointer" : "opacity-25 pe-none"} icon-m mr-4`}
                  />
                </div>

                <div className="tagging-step-inner-content py-2">
                  <p className="text-l">
                    {compact(
                      passage?.original_text_sentence_tokenized.map((sentence: string, idx: number) => {
                        const display = sentenceIndexes.some((idx2) => Math.abs(idx - idx2) <= 1)
                        if (!display) return

                        const highlight = sentenceIndexes.some((idx2) => Math.abs(idx - idx2) === 0)
                        return (
                          <span key={idx} className={`mr-1 ${highlight ? "text--black" : "text--gray3"}`}>
                            {sentence}
                          </span>
                        )
                      })
                    )}
                  </p>
                </div>

                <div>
                  <img
                    onClick={() => setSentenceIndexes(sentenceIndexes.slice(0, sentenceIndexes.length - 1))}
                    src={minusIcon}
                    className={`${canRemoveNextSentence ? "pointer" : "opacity-25 pe-none"} icon-m mr-4`}
                  />

                  <img
                    onClick={() => setSentenceIndexes(sentenceIndexes!.concat(last(sentenceIndexes)! + 1))}
                    src={plusIcon}
                    className={`${canAddNextSentence ? "pointer" : "opacity-25 pe-none"} icon-m mr-4`}
                  />
                </div>
              </div>

              <Actions
                isLoading={isQuerying[QueryName.AnnotateText]}
                disabled={!sentenceIndexes?.length}
                handleClickedContinue={() => setStep(Step.EditText)}
              />
            </div>
          )}

          {step === Step.EditText && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  <textarea onChange={(e) => setText(e.target.value)} value={text} autoFocus className="p-2 m-0 text-l rounded w-100 flex-grow" />

                  <div className="d-flex justify-content-between align-items-start">
                    <p className="explainer mb-0 mt-1">EDIT TEXT CONTENT</p>
                  </div>
                </div>
              </div>

              <Actions
                isLoading={isLoading || isQuerying[QueryName.AnnotateText]}
                disabled={!text?.length}
                handleClickedContinue={sentenceTokenizeText}
              />
            </div>
          )}

          {step === Step.GamifiedSentence && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  <p className="mb-3">
                    {gamifiedSentenceOptions?.map((tokens: any[], idx: number) => {
                      const isGamified = idx == gamifiedSentenceIndex

                      return (
                        <span
                          onClick={() => setGamifiedSentenceIndex(idx)}
                          key={idx}
                          className={`mb-1 mr-1 text-l pointer ${isGamified ? "text--primary" : "text--gray4"}`}
                        >
                          {tokensJoined(tokens)}
                        </span>
                      )
                    })}
                  </p>

                  <p className="explainer mb-0 mt-1">SELECT GAMIFIED SENTENCE</p>
                </div>
              </div>

              <Actions
                isLoading={isQuerying[QueryName.AnnotateText]}
                disabled={gamifiedSentenceIndex === undefined}
                handleClickedContinue={annotateText}
              />
            </div>
          )}

          {step === Step.ValidateSubject && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  <div className="m-0 d-flex flex-wrap align-items-center">{tripleComponents(annotated?.tokens, subject)}</div>

                  <div className="m-0 mt-5 text-l d-flex flex-wrap align-items-center">
                    {tripleComponents(subject)} <p className="mb-0 ml-2">is the subject?</p>
                  </div>
                </div>

                <div>
                  <Button onClick={handleNoTriple} outline color="danger" className="min-width-100px">
                    No triple
                  </Button>
                </div>
              </div>

              <Actions handleClickedTrue={goToRelationStep} handleClickedFalse={() => setStep(Step.CorrectSubject)} />
            </div>
          )}

          {step === Step.CorrectSubject && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className={`tagging-step-inner-content`}>
                  {passageComponent(true)}

                  <div className="m-0 mt-5 text-l d-flex flex-wrap align-items-center">
                    {tripleComponents(annotated?.tokens.filter((_, idx) => tokenIndexes.includes(idx)))}
                    <p className={`m-0 ${tokenIndexes.length ? "ml-2" : ""}`}>{tokenIndexes.length ? "is the subject" : "Select the subject."}</p>
                  </div>
                </div>

                <div>
                  <Button onClick={handleNoTriple} outline color="danger" className="min-width-100px">
                    No triple
                  </Button>
                </div>
              </div>

              <Actions disabled={!tokenIndexes.length} handleClickedContinue={handleCorrectedSubject} />
            </div>
          )}

          {step === Step.ValidateRelation && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  <div className="m-0 text-l d-flex flex-wrap align-items-center">{tripleComponents(annotated?.tokens, relation)}</div>

                  <div className="m-0 mt-5 text-l d-flex flex-wrap align-items-center">
                    {tripleComponents(relation)} <p className="mb-0 ml-2">is the relation?</p>
                  </div>
                </div>
              </div>

              <Actions handleClickedTrue={goToObjectStep} handleClickedFalse={() => setStep(Step.CorrectRelation)} />
            </div>
          )}

          {step === Step.CorrectRelation && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  {passageComponent(true)}

                  <div className="m-0 mt-5 text-l d-flex flex-wrap align-items-center">
                    {tripleComponents(annotated?.tokens.filter((_, idx) => tokenIndexes.includes(idx)))}
                    <p className={`m-0 ${tokenIndexes.length ? "ml-2" : ""}`}>{tokenIndexes.length ? "is the relation" : "Select the relation."}</p>
                  </div>
                </div>
              </div>

              <Actions disabled={!tokenIndexes.length} handleClickedContinue={handleCorrectedRelation} />
            </div>
          )}

          {step === Step.ValidateObject && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  <div className="m-0 d-flex flex-wrap align-items-center">{tripleComponents(annotated?.tokens, object)}</div>

                  <div className="m-0 mt-5 text-l d-flex flex-wrap align-items-center">
                    {tripleComponents(object)} <p className="mb-0 ml-2">is the object?</p>
                  </div>
                </div>
              </div>

              <Actions handleClickedTrue={() => setStep(Step.TriplePermutations)} handleClickedFalse={() => setStep(Step.CorrectObject)} />
            </div>
          )}

          {step === Step.CorrectObject && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  {passageComponent(true)}

                  <div className="m-0 mt-5 text-l d-flex flex-wrap align-items-center">
                    {tripleComponents(annotated?.tokens.filter((_, idx) => tokenIndexes.includes(idx)))}
                    <p className={`m-0 ${tokenIndexes.length ? "ml-2" : ""}`}>{tokenIndexes.length ? "is the object" : "Select the object."}</p>
                  </div>
                </div>
              </div>

              <Actions disabled={!tokenIndexes.length} handleClickedContinue={handleCorrectedObject} />
            </div>
          )}

          {step === Step.TriplePermutations &&
            (possiblePermutations ? (
              <div className="tagging-step">
                <div />

                <div className="tagging-step-content">
                  <div className="tagging-step-inner-content">
                    <div>
                      {possiblePermutations.map((p, idx) => {
                        const include = permutationIndexes.includes(idx)

                        return (
                          <p
                            onClick={() => setPermutationIndexes(include ? without(permutationIndexes, idx) : permutationIndexes.concat(idx))}
                            key={idx}
                            role="button"
                            className={`mb-2 bold text-l d-flex align-items-center ${include ? "text--primary" : "text--gray3"}`}
                          >
                            {joinTokens(flatten([p.subject, p.relation!, p.object]))}
                          </p>
                        )
                      })}
                    </div>

                    <p className="mt-4 text-l mb-0">
                      Save <span className="bold">{permutationIndexes.length}</span> triple(s).
                    </p>
                  </div>
                </div>

                <Actions disabled={!permutationIndexes.length} handleClickedContinue={goToChoiceSetsStep} />
              </div>
            ) : (
              <div className="tagging-step">
                <div />

                <div className="tagging-step-content">
                  <div className="tagging-step-inner-content">
                    {passageComponent(true, Step.TriplePermutations)}

                    <div className="my-4">
                      <Button
                        className="px-5"
                        onClick={addTriplePermutation}
                        size="lg"
                        disabled={!Object.values(customTriple).every((indexes) => indexes.length)}
                        color="success"
                      >
                        Add
                      </Button>

                      <p className="explainer mb-0 mt-1">ADD CUSTOM TRIPLES</p>
                    </div>

                    <div style={{ flex: "1 1 0px" }} className="d-flex flex-column">
                      {customTriples.map((t, idx) => (
                        <div
                          onClick={() => setCustomTriples(customTriples.filter((_, idx2) => idx !== idx2))}
                          key={idx}
                          className="d-flex flex-wrap mb-2 pointer"
                        >
                          {tripleComponents(annotated?.tokens.filter((_, idx) => flatten(Object.values(t)).includes(idx)))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <Actions handleClickedContinue={goToChoiceSetsStep} />
              </div>
            ))}

          {step === Step.ChoiceSets && <ChoiceSets annotated={annotated!} handleClickedContinue={goToRedHerringsStep} />}

          {step === Step.RedHerrings && (
            <div className="tagging-step">
              <div />

              <div className="tagging-step-content">
                <div className="tagging-step-inner-content">
                  {
                    invalid.map(({ token, idx }) => (
                      <TokenRedHerrings
                        annotated={annotated!}
                        handleUpdateRedHerrings={handleUpdateRedHerrings}
                        idx={idx}
                        key={idx}
                        redHerringSetIdx={redHerringSetIdx}
                        token={token}
                      />
                    ))[tokenIdx]
                  }

                  <div>
                    <Button
                      onClick={() => handleSelectAllRedHerrings(!allRedHerringsAccepted(annotated!.tokens[invalid[tokenIdx].idx], redHerringSetIdx))}
                      color="primary"
                    >
                      {allRedHerringsAccepted(annotated!.tokens[invalid[tokenIdx].idx], redHerringSetIdx) ? "Unselect" : "Select"} all
                    </Button>
                  </div>

                  <p className="explainer mb-0 mt-3">SELECT RED HERRINGS</p>
                </div>
              </div>

              <Actions handleClickedContinue={continueInvalid} />
            </div>
          )}

          {step === Step.CustomQuestions && (
            <div className="tagging-step">
              <div />

              {passage && annotated && (
                <CustomQuestions
                  annotated={annotated}
                  handleQueryChatGpt={handleQueryChatGpt}
                  isNetworking={isNetworking}
                  passage={passage}
                  posttext={posttext}
                  pretext={pretext}
                  questions={questions}
                  setQuestions={setQuestions}
                />
              )}

              <Actions handleClickedContinue={() => setStep(Step.Metadata)} />
            </div>
          )}

          {step === Step.Metadata && (
            <div className="tagging-step">
              <div />

              <Metadata setMetadataIsValid={setMetadataIsValid} annotated={annotated} passage={passage} passageText={passageText} />

              <Actions disabled={!metadataIsValid} handleClickedContinue={() => setStep(Step.Finish)} />
            </div>
          )}

          {step === Step.Finish && (
            <div className="tagging-step">
              <div />

              <Spinner color="primary" />

              <div />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
