import React from "react"
import compact from "lodash/compact"
import { Button, Input } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import { Annotated, UnenrichedPassage, updatePassageCustomQuestionsAction } from "../../hasura/slices/passage"
import { UserState, userSelector } from "../../hasura/slices/user"
import { pluralize } from "../../lib/helpers"
import { CustomQuestion, ChatGPT } from "../../services/chatGpt"

// @ts-ignore
import deleteIcon from "../../lib/images/delete.svg"

interface CustomQuestionProps {
  deleteQuestion: () => void
  editedAnswer: (answer: string) => void
  editedChoice: (choice: string, idx: number, isAnswer: boolean) => void
  editedPrompt: (prompt: string) => void
  idx: number
  question: CustomQuestion
}

function Question(props: CustomQuestionProps) {
  const { question, answer, choices } = props.question

  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <p className="mb-0 mr-2 bold text-s text--gray7">{props.idx}</p>

        <Input onChange={(e: any) => props.editedPrompt(e.target.value)} type="text" value={question} />

        <img onClick={props.deleteQuestion} className="svg-icon hover ml-4 mr-3" src={deleteIcon} />
      </div>

      {choices.map((choice, idx) => {
        const isAnswer = answer === choice
        return (
          <div className="d-flex align-items-center gap-10px my-1" key={idx}>
            <p onClick={() => props.editedAnswer(choice)} style={{ width: "20px", opacity: isAnswer ? 1 : 0.1 }} className="m-0 pointer transition-f">
              {answer === choice ? "✔️" : "✔️"}
            </p>
            <Input onChange={(e: any) => props.editedChoice(e.target.value, idx, isAnswer)} className="width-300px" value={choice} />
          </div>
        )
      })}
    </div>
  )
}

interface CustomQuestionsProps {
  annotated: Annotated
  handleQueryChatGpt: (model: ChatGPT) => void
  isNetworking: boolean
  passage: UnenrichedPassage
  posttext: string | null
  pretext: string | null
  questions: CustomQuestion[]
  setQuestions: (questions: CustomQuestion[]) => void
}

export default function CustomQuestions(props: CustomQuestionsProps) {
  const dispatch = useDispatch()

  const { questions, isNetworking } = props
  const { id } = props.passage

  const { accessToken }: UserState = useSelector(userSelector)

  const passageText = compact([props.annotated.pretext || props.pretext, props.annotated.text, props.annotated.posttext || props.posttext]).join(" ")

  const editedPrompt = (prompt: string, idx: number) => {
    const newQuestions = [...questions]
    newQuestions[idx].question = prompt
    props.setQuestions(newQuestions)
  }

  const editedAnswer = (answer: string, idx: number) => {
    const newQuestions = [...questions]
    newQuestions[idx].answer = answer
    props.setQuestions(newQuestions)
  }

  const editedChoice = (prompt: string, choiceIdx: number, isAnswer: boolean, questionIdx: number) => {
    const newQuestions = [...questions]
    newQuestions[questionIdx].choices[choiceIdx] = prompt
    if (isAnswer) newQuestions[questionIdx].answer = prompt
    props.setQuestions(newQuestions)
  }

  const deleteQuestion = (idx: number) => {
    const newQuestions = [...questions]
    newQuestions.splice(idx, 1)
    props.setQuestions(newQuestions)
  }

  const handleSave = () => {
    if (!accessToken || !id) return

    dispatch(updatePassageCustomQuestionsAction(accessToken, id, questions))
  }

  return (
    <div className="tagging-step-content">
      <p className="text-l">{passageText}</p>

      <div className="overflow-scroll" style={{ maxHeight: "60vh" }}>
        {questions.map((question, idx) => (
          <Question
            idx={idx + 1}
            editedPrompt={(prompt) => editedPrompt(prompt, idx)}
            editedAnswer={(answer) => editedAnswer(answer, idx)}
            editedChoice={(choice, idx2, isAnswer) => editedChoice(choice, idx2, isAnswer, idx)}
            key={idx}
            question={question}
            deleteQuestion={() => deleteQuestion(idx)}
          />
        ))}
      </div>

      <div className="d-flex gap-20px align-items-end">
        <Button className="width-fit-content" color="primary" disabled={isNetworking} onClick={() => props.handleQueryChatGpt(ChatGPT.Model3p5)}>
          Chat GPT 3.5
        </Button>

        <Button className="width-fit-content" color="primary" disabled={isNetworking} onClick={() => props.handleQueryChatGpt(ChatGPT.Model4)}>
          Chat GPT 4
        </Button>

        <Button className="width-fit-content mt-2" color="success" onClick={handleSave} disabled={isNetworking}>
          Save {pluralize("question", questions.length)}
        </Button>
      </div>
    </div>
  )
}
