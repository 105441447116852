export enum Step {
  SelectSentences = "Filter Text",
  EditText = "Edit Text",
  GamifiedSentence = "GamifiedSentence",
  ValidateSubject = "Subject",
  CorrectSubject = "CorrectSubject",
  ValidateRelation = "Relation",
  CorrectRelation = "CorrectRelation",
  ValidateObject = "Object",
  CorrectObject = "CorrectObject",
  TriplePermutations = "Triples",
  ChoiceSets = "Choice Sets",
  CustomQuestions = "Custom Questions",
  RedHerrings = "Red Herrings",
  Metadata = "Metadata",
  Finish = "Finish",
}

export const STEP_ORDER = [
  Step.SelectSentences,
  Step.EditText,
  Step.GamifiedSentence,
  Step.ValidateSubject,
  Step.CorrectSubject,
  Step.ValidateRelation,
  Step.CorrectRelation,
  Step.ValidateObject,
  Step.CorrectObject,
  Step.TriplePermutations,
  Step.ChoiceSets,
  Step.RedHerrings,
  Step.CustomQuestions,
  Step.Metadata,
]

export const DISPLAY_STEPS = [Step.SelectSentences, Step.EditText, Step.ChoiceSets, Step.CustomQuestions, Step.Metadata]

export const DISPLAY_STEPS_TEXT = [
  Step.SelectSentences,
  Step.EditText,
  Step.ValidateSubject,
  Step.ValidateRelation,
  Step.ValidateObject,
  Step.TriplePermutations,
  Step.ChoiceSets,
  Step.CustomQuestions,
  Step.Metadata,
]
