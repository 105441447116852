import React, { useState } from "react"
import { Button, Modal, ModalBody } from "reactstrap"

import { Annotated, Token, UnenrichedPassage } from "../../hasura/slices/passage"
import { imageFor, includeRightMargin } from "../../lib/helpers"

interface Props {
  annotated?: Annotated
  text?: string
  close: () => void
  passage: UnenrichedPassage
}

export default function ConfirmModal(props: Props) {
  const [displayFull, setDisplayFull] = useState(false)

  const { tokens, triples, pretext, posttext } = props.annotated || {}
  const { illustrated_passages, quality, difficulty, original_text_sentence_tokenized, original_text_target_sentence_index, source } = props.passage

  const joinedTokens = (tokens: Token[]) =>
    tokens.map((t, idx) => (
      <span key={idx} className={`d-inline-block ${includeRightMargin(tokens, t, idx) ? "mr-1" : ""}`}>
        {t.value}
      </span>
    ))

  const redHerringTokens = (tokens || []).filter((t) => t.acceptedRedHerrings?.length)

  return (
    <Modal fade={false} isOpen size="lg" autoFocus={false} toggle={props.close} centered>
      <ModalBody className="py-4 px-5">
        <p className="subheader">original</p>

        <p className="text-m text--gray8 mb-2">
          {displayFull ? original_text_sentence_tokenized.join(" ") : original_text_sentence_tokenized[original_text_target_sentence_index!]}
          <span className="d-block text--gray6 text-xxs ml-1">— {source}</span>
        </p>

        <Button size="sm" onClick={() => setDisplayFull(!displayFull)} color="primary" outline>
          {displayFull ? "Hide" : "Show"} all
        </Button>

        <p className="subheader">text</p>

        {tokens ? (
          <p className="text-m">
            <span className="text--gray7 mr-1">{pretext}</span>

            {joinedTokens(tokens)}

            <span className="text--gray7 ml-1">{posttext}</span>
          </p>
        ) : (
          <p className="text-m">{props.text}</p>
        )}

        <p className="subheader">triples</p>

        <div className="text-m">
          {triples?.length ? (
            triples.map((t) => (
              <p className="m-0">
                {joinedTokens(tokens!.filter((t2) => t.subjectIndexes.includes(t2.index)))} -{" "}
                {joinedTokens(tokens!.filter((t2) => t.relationIndexes.includes(t2.index)))} -{" "}
                {joinedTokens(tokens!.filter((t2) => t.objectIndexes.includes(t2.index)))}
              </p>
            ))
          ) : (
            <p className="font-italic m-0">—</p>
          )}
        </div>

        <p className="subheader">red herrings</p>

        <div className="text-m">
          {redHerringTokens.length ? (
            redHerringTokens.map((t) => (
              <p className="m-0">
                <span className="mr-1">{t.value} -</span> {t.acceptedRedHerrings?.join(", ")}
              </p>
            ))
          ) : (
            <p className="font-italic m-0">—</p>
          )}
        </div>

        <p className="subheader">metadata</p>

        <div className="text-m">
          <p className="m-0">
            <span className="semibold text--gray7 mr-2">Difficulty</span> {difficulty || "—"}
          </p>

          <p className="m-0">
            <span className="semibold text--gray7 mr-2">Quality</span> {quality || "—"}
          </p>

          {illustrated_passages.length > 0 && (
            <div className="mt-4">
              {illustrated_passages.map((i) => (
                <img style={{ height: "200px", width: "auto" }} className="mr-3" src={imageFor(i.image.s3_path)} key={i.image.s3_path} />
              ))}
            </div>
          )}
        </div>

        <div className="float-right mt-3">
          <Button className="min-width-100px" outline onClick={props.close} size="lg" color="primary">
            Close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
