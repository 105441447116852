import compact from "lodash/compact"
import flatten from "lodash/flatten"
import groupBy from "lodash/groupBy"
import isEqual from "lodash/isEqual"
import sortBy from "lodash/sortBy"
import uniqWith from "lodash/uniqWith"

import { POS, Token } from "../../hasura/slices/passage"

export const hasBeepBoop = (tokens: Token[]) => {
  const grouped = groupBy(tokens, "pos")
  const adjectives = grouped[POS.Adjective]?.length || 0
  return adjectives > 2 || Object.keys(grouped).some((pos) => ![POS.Noun, POS.Adjective].includes(pos as POS))
}

export const getPermutations = (tokens: Token[] | undefined, isSubject: boolean): Token[][] | undefined => {
  if (!tokens) return []

  if (hasBeepBoop(tokens)) return

  const adjectives = tokens.filter((o) => o.pos === POS.Adjective).slice(0, 2)
  const nouns = tokens.filter((o) => o.pos === POS.Noun)
  const other = tokens.filter((t) => ![POS.Adjective, POS.Noun].includes(t.pos))

  // @ts-ignore
  return compact(
    [[...nouns], [adjectives[0]], [adjectives[1]], [adjectives[0], ...nouns], [adjectives[1], ...nouns], [adjectives[0], adjectives[1], ...nouns]]
      .filter((arr) => arr.filter((t) => t).length && !arr.some((o) => !o) && (!isSubject || arr.some((a) => a?.pos === POS.Noun)))
      .map((tokens) => sortBy(tokens.concat(...other), "index"))
  )
}

const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export const joinTokens = (tokens: Token[]) =>
  capitalizeFirstLetter(tokens.map((t) => t.value).join(" ") + ".")
    .replace(" ,", ",")
    .replace(" .", ".")
    .replace("..", ".")

export const createPermutations = (subject: Token[] | undefined, object: Token[] | undefined, relation: Token[] | undefined) => {
  const possiblePermutationsSubject = getPermutations(subject, true)
  const possiblePermutationsObject = getPermutations(object, false)

  return (
    possiblePermutationsSubject &&
    possiblePermutationsObject &&
    uniqWith(
      flatten(possiblePermutationsSubject.map((subject) => possiblePermutationsObject.map((object) => ({ subject, object, relation })))),
      isEqual
    )
  )
}
