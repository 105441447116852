import React, { useState } from "react"
import compact from "lodash/compact"
import range from "lodash/range"
import without from "lodash/without"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"

import AddToBook from "../book/addToBook"
import { ADMIN_ARCHIVE_PASSAGE } from "../../lib/pedagogeEventTypeIds"
import { NotificationId, setNotificationAction } from "../../hasura/slices/notification"
import { Step, STEP_ORDER, DISPLAY_STEPS, DISPLAY_STEPS_TEXT } from "./config"
import { fetchPassagesForIdsAction, UnenrichedPassage, updatePassageStatusAction } from "../../hasura/slices/passage"
import { passagesQueueFor } from "../../lib/helpers"
import { updateAdminPassagesQueueAction, userSelector, UserState } from "../../hasura/slices/user"

interface Props {
  concepts: string[]
  displayConfirmModal: () => void
  id?: number
  inQueue: boolean
  isReviewing?: string
  passage?: UnenrichedPassage
  passageText?: string
  reviewItem: (pedagogueEventId: number) => void
  setStep: (step: Step) => void
  skip: () => void
  step: Step
}

export default function TaggingNavigation(props: Props) {
  const dispatch = useDispatch()

  const [_, setIsNetworking] = useState(false)

  const { accessToken, user }: UserState = useSelector(userSelector)

  const currentPassageRemovedQueue = compact(without(passagesQueueFor(user), props.id))

  const passageMenu = (
    <div className="border p-2 rounded width-250px bg--gray2">
      <p className="bold mb-1 text-xxs">MENU</p>

      <p
        onClick={async () => {
          setIsNetworking(true)
          if (props.isReviewing) {
            await props.reviewItem(ADMIN_ARCHIVE_PASSAGE)
          } else {
            await dispatch(updateAdminPassagesQueueAction(accessToken!, user!.id, currentPassageRemovedQueue.join()))
            await dispatch(updatePassageStatusAction(accessToken!, props.id!, "review"))
            dispatch(setNotificationAction(NotificationId.PassageArchived))
            props.skip()
          }
          setIsNetworking(false)
        }}
        className="m-0 pointer hover-underline"
      >
        Archive passage
      </p>

      {props.inQueue && !props.isReviewing && (
        <p
          onClick={async () => {
            setIsNetworking(true)
            await dispatch(updateAdminPassagesQueueAction(accessToken!, user!.id, currentPassageRemovedQueue.concat(props.id!).join()))
            dispatch(setNotificationAction(NotificationId.PassagePunted))
            props.skip()
            setIsNetworking(false)
          }}
          className="m-0 pointer hover-underline"
        >
          Punt passage
        </p>
      )}

      <p onClick={props.displayConfirmModal} className="m-0 pointer hover-underline">
        Review passage
      </p>

      <p onClick={() => navigate("/home")} className="mt-2 mb-0 pointer hover-underline">
        Go home
      </p>
    </div>
  )

  const bookComponents = (
    <div className="mt-3 p-2 border rounded width-fit-content width-250px bg--white ">
      <p className="bold mb-2 text-xxs">BOOKS</p>

      {props.passage && (
        <AddToBook
          menuPlacement="bottom"
          passage={props.passage}
          passageText={props.passageText}
          onDone={() => dispatch(fetchPassagesForIdsAction(accessToken!))}
        />
      )}
    </div>
  )

  return (
    <div className="py-3 px-3">
      <div className="d-flex">
        {compact(
          range(Object.keys(Step).length).map((idx) => {
            if (!DISPLAY_STEPS.includes(STEP_ORDER[idx])) return null

            const stepIncomplete = idx > STEP_ORDER.indexOf(props.step)

            return (
              <div
                key={idx}
                onClick={() => props.setStep(STEP_ORDER[idx])}
                role="button"
                className={`flex-even ${idx === 0 ? "" : "ml-2"} text-center`}
              >
                <div style={{ height: "3px" }} className={`transition-m ${stepIncomplete ? "bg--gray3" : "bg--black"}`}></div>
                {DISPLAY_STEPS_TEXT.includes(STEP_ORDER[idx]) && (
                  <p className={`single-line regular mb-0 text-xs ${stepIncomplete ? "text--gray3" : ""}`}>{STEP_ORDER[idx]}</p>
                )}
              </div>
            )
          })
        )}
      </div>

      <div className="mt-3 position-absolute">
        {passageMenu}

        {bookComponents}
      </div>
    </div>
  )
}
