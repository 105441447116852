import React from "react"
import { Spinner } from "reactstrap"

export enum ActionType {
  TrueFalse,
  Continue,
}

interface Props {
  disabled?: boolean
  handleClickedTrue?: () => void
  handleClickedFalse?: () => void
  handleClickedContinue?: () => void
  isLoading?: boolean
}

export default function Actions(props: Props) {
  const isContinue = props.handleClickedContinue !== undefined

  return (
    <div className="text-m d-flex w-100 bold">
      {isContinue && (
        <div
          onClick={props.handleClickedContinue}
          className={`default-letter-spacing text--white transition-m m-0-auto flex-even border-top text-center py-3 ${
            props.disabled || props.isLoading ? "bg--gray3 pe-none" : "pointer bg--primary"
          }`}
        >
          {props.isLoading ? <Spinner size="sm" /> : <p className="m-0">CONTINUE (→)</p>}
        </div>
      )}

      {!isContinue && (
        <p
          role="button"
          onClick={props.handleClickedTrue}
          className={`default-letter-spacing m-0-auto flex-even border-top text-center py-3 bg--primary text-white ${
            props.disabled ? "pe-none bg--gray3" : ""
          }`}
        >
          TRUE
        </p>
      )}

      {!isContinue && (
        <p
          role="button"
          onClick={props.handleClickedFalse}
          className={`default-letter-spacing m-0-auto flex-even border-top text-center py-3 ${props.disabled ? "pe-none" : ""}`}
        >
          FALSE
        </p>
      )}
    </div>
  )
}
