import React from "react"

import { Annotated, Token } from "../../hasura/slices/passage"
import { includeRightMargin } from "../../lib/helpers"

interface Props {
  annotated: Annotated
  handleUpdateRedHerrings: (accept: boolean, concept: string) => void
  idx: number
  redHerringSetIdx: number
  token: Token
}

export default function TokenRedHerrings(props: Props) {
  const redHerringSet = props.token.redHerringSets![props.redHerringSetIdx]
  const startTokens = props.annotated?.tokens.slice(0, props.idx)
  const endTokens = props.annotated.tokens.slice(props.idx + 1, props.annotated.tokens.length)
  const redHerringToken = props.annotated.tokens[props.idx]

  return (
    <p className="m-0 text-l">
      {props.idx > 0 && <span>{startTokens.map((t, idx) => `${t.value}${includeRightMargin(startTokens, t, idx) ? " " : ""}`).join("")}</span>}

      <span style={{ textDecoration: "line-through" }} className="mx-1">
        {redHerringToken.value}
      </span>

      <span>(</span>

      {redHerringSet.values
        .map((r, idx) => {
          const isAccepted = (props.token.acceptedRedHerrings || []).includes(r)
          return (
            <span
              className={`bold ${isAccepted ? "text--primary" : "text--gray4"}`}
              role="button"
              onClick={() => props.handleUpdateRedHerrings(!isAccepted, r)}
              key={idx}
            >
              {r}
            </span>
          )
        })
        .reduce((prev: any, curr: any, idx: number) => (prev.length ? [prev, <span key={`separator-${idx}`}> / </span>, curr] : [curr]), [])}

      <span>)</span>

      <span className="ml-1">{endTokens.map((t, idx) => `${t.value}${includeRightMargin(endTokens, t, idx) ? " " : ""}`).join("")}</span>
    </p>
  )
}
